import { render, staticRenderFns } from "./ProjectDelete.vue?vue&type=template&id=32a6e186&scoped=true&"
import script from "./ProjectDelete.vue?vue&type=script&lang=js&"
export * from "./ProjectDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a6e186",
  null
  
)

export default component.exports