<template>
    <div class="pa-3 text-center grey--text">
        No data is available yet
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
